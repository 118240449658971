import {React, useEffect, useState} from '../../imports/imports-packege';
import {NotificationError, NotificationSuccess, NotificationWaiting} from "../../imports/imports-images";
import NotificationPopupStyle from './NotificationPopupStyle.module.scss';
import {useNotificationContext} from "./NotificationContext";
import {NOTIFICATION_TYPES} from "./const";

const NotificationPopup = () => {
    const {notification} = useNotificationContext();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!notification?.context?.message) {
            setIsVisible(false);
            return;
        }

        setIsVisible(true);

        const timerId = setTimeout(() => {
            setIsVisible(false);
        }, 3000);

        return () => {
            clearTimeout(timerId);
        };
    }, [notification]);

    const getNotificationImage = () => {
        switch (notification?.type) {
            case NOTIFICATION_TYPES.ERROR:
                return NotificationError;
            case NOTIFICATION_TYPES.SUCCESS:
                return NotificationSuccess;
            case NOTIFICATION_TYPES.WAITING:
                return NotificationWaiting;
            default:
                return NotificationError;
        }
    }

    return (
        <div
            className={`${NotificationPopupStyle.ErrorPopup} ${isVisible && NotificationPopupStyle.visible} ${NotificationPopupStyle[notification?.type ?? NOTIFICATION_TYPES.ERROR]}`}>
            <img src={getNotificationImage()} alt={NOTIFICATION_TYPES[notification?.type ?? NOTIFICATION_TYPES.ERROR]}/>
            <p className={NotificationPopupStyle.Text}>{notification?.context?.message}</p>
        </div>
    );
};

export default NotificationPopup;