import {React, StatePool, useEffect, useState} from '../../../../imports/imports-packege';
import  '../PWAPreviewStyle.css';
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";

const ButtonSection = () => {
    let {data, updateData} = useData();
    const [pwaDesign, setPwaDesign] = useState(data.pwaDesign);

    useEffect(() => {
        setPwaDesign(data.pwaDesign)
    }, [data.pwaDesign])
    const ListTextSecurity = TranslateText[data.langCode];

    return (
        <div className="button-action">
            <div className="button-action__button-container" style={{backgroundColor: pwaDesign ? '#00a173' : '#0d57cf'}}>
                <div className="button-action__button">
                    {data.langCode.length > 0 ? ListTextSecurity['install_btn'] : 'Install'}
                </div>
            </div>
        </div>
    )
}

export default ButtonSection