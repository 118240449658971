import {React, useEffect} from "../../../imports/imports-packege";
import InputStyle from "./InputStyle.module.scss";
import {useState} from "react";

const Input = ({placeholder, inputValue, valueSave, error}) => {
    const [value, setValue] = useState();
    const handleSetValue = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        inputValue(newValue);
    }
    useEffect(() => {
    }, [value]);
    return (
        <>
            <input
                type="text"
                className={`${InputStyle.InputField} ${error && InputStyle.InputField_error}`}
                placeholder={placeholder}
                onChange={handleSetValue}
                value={valueSave !== '' ? valueSave : ''}
            />
        </>
    );
};

export default Input;