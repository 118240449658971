export default {
    common: {
        copy: "Copy",
        appStyle: "App Design",
        error: "incorrect {{place}} - error",
        select: 'Select',
        uploading: 'uploading...'
    },
    alerts: {
        pwaSuccess: 'PWA has been successfully loaded into the keitaro!',
        pwaError: 'Error when sending to keitaro \b Follow the link in the TG again',
        addSendLink: 'Add a link to the game',
        sendLinkError: 'Oops, it looks like you can\'t steal this particular app from the Play Market, please try another one'
    },
    app: {
        title: "App",
        name: {
            title: "Name",
            placeholder: "App Name"
        },
        lang: {
            title: "Language PWA",
        },
        design: {
            title: "Copy app design from Play Market ",
            placeholder: "Link from Play Market"
        },
        icon: {
            title: "Icon",
            placeholder: {
                1: "From",
                2: "512х512px",
            }
        },
        screenshots: {
            title: "Screenshots",
            placeholder: {
                1: "From",
                2: "720х1280px",
            }
        }
    },
    parameters: {
        title: "Parameters",
        author: {
            title: "Author",
            placeholder: "Play Dev"
        },
        countComments: {
            title: "Comments",
            placeholder: "1034"
        },
        age: {
            title: "Age"
        },
        size: {
            title: "Size (MB)",
            placeholder: "6"
        },
        installations: {
            title: "Installations",
            placeholder: "2034"
        },
        landDescriptionTitle: {
            title: "Title Description",
            placeholder: "Title text"
        },
        landDescriptionText: {
            title: "Description",
            placeholder: "Your Description"
        },
        rating: {
            title: "App Rating",
            placeholder: "Rating Number"
        },
        ratingChart: {
            title: "App Rating chart",
        },
        categories: {
            title: "App Categories",
        },
        recommended: {
            title: "Recommended App",
        }
    },
    review: {
        title: "Comments",
        countComments: 'Count Comments',
        comment: {
            title: "Comment {{index}}",
            icon: 'User Icon',
            iconPlaceholder: 'Select user logo',
            name: 'User Name',
            rating: 'Comment Rating',
            date: 'Comment Date',
            text: {
                title: 'Comment Text',
                placeholder: 'Your text'
            },
            response: {
                title1: 'Reply to a comment',
                title2: '*If the field is empty, it will not appear on the page',
                placeholder: 'Developer response'
            }
        },
    }
}