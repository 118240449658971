import {createContext, useContext, useState} from "react";
import {NOTIFICATION_TYPES} from "./const";

const NotificationContext = createContext();

export const NotificationContextProvider = ({children}) => {
    const [notification, setNotitication] = useState({type: '', context: {place: '', message: ''}});

    const notifyError = ({place, message}) => {
        setNotitication({
            type: NOTIFICATION_TYPES.ERROR,
            context: {place, message}
        })
    }

    const notifySuccess = ({place, message}) => {
        setNotitication({
            type: NOTIFICATION_TYPES.SUCCESS,
            context: {place, message}
        })
    }

    const notifyWaiting = ({place, message}) => {
        setNotitication({
            type: NOTIFICATION_TYPES.WAITING,
            context: {place, message}
        })
    }

    return (
        <NotificationContext.Provider value={{notification, notifyError, notifySuccess, notifyWaiting}}>
            {children}
        </NotificationContext.Provider>
    );
}

export const useNotificationContext = () => {
    return useContext(NotificationContext)
}