import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./en";
import ua from "./ua";

export const LANGUAGES = {
    EN: 'en',
    UA: 'ua',
};

export const LANG_KEY = 'i18nKey';

const resources = {
    [LANGUAGES.EN]: {
        translation: en,
    },
    [LANGUAGES.UA]: {
        translation: ua,
    },
};

export default () => i18n.use(LanguageDetector).use(initReactI18next).init({
    resources,
    detection: {
        order: ['localStorage'],
        lookupLocalStorage: LANG_KEY,
        caches: ['localStorage'],
    },
    fallbackLng: LANGUAGES.UA,
    interpolation: {
        escapeValue: false,
    },
});