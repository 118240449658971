import Aviator from "../../assets/images/recommendedIcon/aviator.png"
import Leprechaun from "../../assets/images/recommendedIcon/Leprechaun.jpeg"
import LuckySpin from "../../assets/images/recommendedIcon/Lucky-spin-slot.jpeg"
import MagikCasino from "../../assets/images/recommendedIcon/magik-casino.png"
import PokerSlotSpin from "../../assets/images/recommendedIcon/Poker-slot-spin.jpeg"
import SweetBonanza from "../../assets/images/recommendedIcon/sweet-bonanza.png"

const AppListUserAge = [
    {value: '12', label: '+12'},
    {value: '14', label: '+14'},
    {value: '16', label: '+16'},
    {value: '18', label: '+18'},
    {value: '21', label: '+21'}
];

const ListOptionLanguageReview = [
    {value: 'en', label: 'Англійська'},
    {value: 'ar', label: 'Арабська'},
    {value: 'ukr', label: 'Українська'},
    {value: 'tur', label: 'Турецька'},
    {value: 'swe', label: 'Швецька'},
    {value: 'es', label: 'Іспанська'},
    {value: 'slo', label: 'Словацька'},
    {value: 'rus', label: 'Свинособацька'},
    {value: 'rum', label: 'Руминська'},
    {value: 'por', label: 'Португальська'},
    {value: 'pol', label: 'Польська'},
    {value: 'nor', label: 'Норвезька'},
    {value: 'lit', label: 'Литовська'},
    {value: 'kor', label: 'Корейська'},
    {value: 'jpn', label: 'Японська'},
    {value: 'hin', label: 'Хінді'},
    {value: 'bg', label: 'Болгарська'},
    {value: 'zh', label: 'Китайська'},
    {value: 'cs', label: 'Чеська'},
    {value: 'da', label: 'Дацька'},
    {value: 'nl', label: 'Нідерландська'},
    {value: 'et', label: 'Естонська'},
    {value: 'fi', label: 'Фінська'},
    {value: 'fr', label: 'Французька'},
    {value: 'de', label: 'Німецька'},
    {value: 'el', label: 'Грецька'},
    {value: 'hu', label: 'Угорська'},
    {value: 'id', label: 'Індонезійська'},
    {value: 'bn', label: 'Бенгальська'},
    {value: 'it', label: 'Італійська'},
    {value: 'slv', label: 'Словенська'},
    {value: 'lav', label: 'Латвійська'},
];

const ListOptionLanguageEnVersion = [
    { "value": "ru", "label": "Russian" },
    { "value": "en", "label": "English" },
    { "value": "fr", "label": "French" },
    { "value": "it", "label": "Italian" },
    { "value": "pt", "label": "Portuguese" },
    { "value": "de", "label": "German" },
    { "value": "tr", "label": "Turkish" },
    { "value": "es", "label": "Spanish" },
    { "value": "ukr", "label": "Ukrainian" },
    { "value": "cs", "label": "Czech" },
    { "value": "pl", "label": "Polish" },
    { "value": "nl", "label": "Dutch" },
    { "value": "sv", "label": "Swedish" },
    { "value": "ro", "label": "Romanian" },
    { "value": "az", "label": "Azerbaijani" },
    { "value": "id", "label": "Indonesian" },
    { "value": "hi", "label": "Hindi" },
    { "value": "no", "label": "Norwegian" },
    { "value": "fi", "label": "Finnish" }
];

const CountReviews = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
    {value: '6', label: '6'},
    {value: '7', label: '7'},
    {value: '8', label: '8'},
    {value: '9', label: '9'},
    {value: '10', label: '10'},
    {value: '11', label: '11'},
    {value: '12', label: '12'},
    {value: '13', label: '13'},
    {value: '14', label: '14'},
    {value: '15', label: '15'},
    {value: '16', label: '16'},
    {value: '17', label: '17'},
    {value: '18', label: '18'},
    {value: '19', label: '19'},
    {value: '20', label: '20'},
];

const AppCategoriesEnVersion = [
    {value: "action", label: "Action"},
    {value: "adventure", label: "Adventure"},
    {value: "arcade", label: "Arcade"},
    {value: "board", label: "Board"},
    {value: "card", label: "Card"},
    {value: "casino", label: "Casino"},
    {value: "casual", label: "Casual"},
    {value: "educational", label: "Educational"},
    {value: "music", label: "Music"},
    {value: "puzzle", label: "Puzzle"},
    {value: "racing", label: "Racing"},
    {value: "role-playing", label: "Role-playing"},
    {value: "simulation", label: "Simulation"},
    {value: "sports", label: "Sports"},
    {value: "strategy", label: "Strategy"},
    {value: "trivia", label: "Trivia"},
    {value: "word", label: "Word"}
];

const AppRecommendedIcon = [
    {value: 'aviator', label: 'Aviator', icon: Aviator},
    {value: 'leprechaun', label: 'Leprechaun', icon: Leprechaun},
    {value: 'lucky-spin', label: 'Lucky spin slot', icon: LuckySpin},
    {value: 'magik-casino', label: 'Magik casino', icon: MagikCasino},
    {value: 'poker-slot', label: 'Poker slot spin', icon: PokerSlotSpin},
    {value: 'sweet-bonanza', label: 'Sweet bonanza', icon: SweetBonanza},
]

export {
    AppRecommendedIcon,
    AppListUserAge,
    ListOptionLanguageReview,
    ListOptionLanguageEnVersion,
    CountReviews,
    AppCategoriesEnVersion
}