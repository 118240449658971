import i18n from "i18next";

const isEmpty = (val) => val === undefined || val === null || val === '';

const dataValidationSchema = {
    appName: {
        validate: (val) => !isEmpty(val)
    },
    langCode: {
        validate: (val) => !isEmpty(val)
    },
    iconUrl: {
        validate: (val) => !isEmpty(val)
    },
    author: {
        validate: (val) => !isEmpty(val)
    },
    countComments: {
        validate: (val) => !isEmpty(val)
    },
    age: {
        validate: (val) => !isEmpty(val)
    },
    appSize: {
        validate: (val) => !isEmpty(val)
    },
    amountInstall: {
        validate: (val) => !isEmpty(val)
    },
    landDescriptionTitle: {
        validate: (val) => !isEmpty(val)
    },
    landDescriptionText: {
        validate: (val) => !isEmpty(val)
    },
}

export const validateData = (data) => {
    const validationResult = {place: '', message: ''};

    Object.keys(data).some(key => {
        if (!dataValidationSchema[key]) {
            return false;
        }

        if (!dataValidationSchema[key].validate(data[key])) {
            validationResult.place = key;
            validationResult.message = i18n.t('common.error', {place: key})
            return true;
        }

        return false;
    })

    return validationResult;
}

