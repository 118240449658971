import {React} from '../../../imports/imports-packege';
import LoaderStyle from './LoaderStyle.module.scss';

const Loader = () => {
    return (
        <div className={LoaderStyle.ContainerLoader}>
            <span className={LoaderStyle.Loader}></span>
        </div>
    );
};

export default Loader;