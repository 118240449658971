import { React, useEffect } from '../../../../imports/imports-packege';
import {BlueStarIcon, reviewPopupIcon} from '../../../../imports/imports-images';
import '../PWAPreviewStyle.css';
import { useData } from "../../../Context/ContextApp";
import ElementPageTranslate from '../Translate/text-translate.json';
import StarGray from "../img/icon/star-full-big.png";
import LogoDefault from "../img/icon/alien.png";
import BottomCommentBlock from "./BottomCommentBlock";

const ReviewSection = () => {
    const { data } = useData();
    const { reviews_data = [] } = data;

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(<img key={i} src={i < rating ? BlueStarIcon : StarGray} alt="star" />);
        }
        return stars;
    };
    const randomNumber = (min, max) =>  {
        let randomNumber = Math.random() * (max - min) + min;
        return randomNumber.toFixed(0);
    }

    return (
        <div className="reviews">
            <div className="reviews__container">
                {reviews_data.map((review, index) => (
                    <div className="reviews__item" key={index}>
                        <div className="reviews__top-info">
                            <div className="reviews__author-info">
                                <div className="reviews__logo-container">
                                    <img src={review.icon || LogoDefault} alt="reviewer icon"/>
                                </div>
                                <div className="reviews__name-reviewer">
                                    {review.name || "Anonymous"}
                                </div>
                            </div>
                            <div className="review_popup">
                                <img src={reviewPopupIcon} alt=""/>
                            </div>
                        </div>
                        <div className="reviews__rating_date">
                        <div className="reviews__rating">
                            {renderStars(review.rating || 5)}
                            </div>
                            <div className="reviews__date">
                                {review.date || "Unknown date"}
                            </div>
                        </div>
                        <div className="reviews__text-comment">
                            {review.commentText || "No comment provided."}
                        </div>
                        {review.dev_response && (
                            <div className="reviews__developer-response">
                                <div className="reviews__name-response">
                                    <span className="name-response">Developer Response</span>
                                    <p className="text">
                                        {review.dev_response}
                                    </p>
                                </div>
                            </div>
                        )}
                        <p className="comment_review">
                            <span>{randomNumber(83, 184)}</span> {ElementPageTranslate[data.langCode ? data.langCode : 'en']['review_text_count_like']}
                        </p>
                        <BottomCommentBlock/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ReviewSection;
