import React, { useState, useEffect } from 'react';
import {useData} from "../../Context/ContextApp";
import SwitcherStyle from './SwitcherStyle.module.scss';

const Switcher = () => {
    const { data, updateData } = useData();
    const [isOn, setIsOn] = useState(false);
    
    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    useEffect(() => {
        updateData({
            ...data,
            pwaDesign: isOn
        })
    }, [data.pwaDesign, isOn])

    return (
        <div className={`${SwitcherStyle.switch} ${isOn ? SwitcherStyle.on : ''}`} onClick={toggleSwitch}>
        <div className={`${SwitcherStyle.circle} ${isOn ? SwitcherStyle.on : ''}`}></div>
        </div>
    );
};

export default Switcher;
