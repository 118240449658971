import './assets/style/AppStyle.css';
import {Header, MainContentSection} from "./imports/imports-components";
import React from 'react';
import initIi18n from './locales/i18n';
import {DataProvider} from './components/Context/ContextApp';
import {NotificationContextProvider} from "./components/NotificationPopup/NotificationContext";
import NotificationPopup from "./components/NotificationPopup/NotificationHandle";

function App() {
    initIi18n();

    return (
        <div className="MainFullScreen">
            <DataProvider>
                <NotificationContextProvider>
                    <NotificationPopup/>
                    <Header/>
                    <MainContentSection/>
                </NotificationContextProvider>
            </DataProvider>
        </div>
    );
}

export default App;
