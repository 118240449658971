import {React, useEffect, useRef, useState, useTranslation} from '../../imports/imports-packege';
import HeaderStyle from './HeaderStyle.module.scss';

const LanguagesSelect = () => {
    const {i18n, t} = useTranslation();

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const menuRef = useRef(null);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang).then(() => setIsOpenMenu(false));
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpenMenu(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className={HeaderStyle.LanguagesSelectContainer} ref={menuRef}>
            <ul className={HeaderStyle.LanguagesList}>
                <li className={HeaderStyle.LanguagesList_Item}
                    onClick={() => setIsOpenMenu(prev => !prev)}>{i18n.language.toUpperCase()}</li>
            </ul>
            {isOpenMenu && (
                <div className={HeaderStyle.Menu}>
                    <ul className={HeaderStyle.LanguagesList}>
                        {Object.keys(i18n.options.resources).filter(key => key !== i18n.language).map((lang) => (
                            <li key={lang} className={HeaderStyle.LanguagesList_Item}
                                onClick={() => changeLanguage(lang)}>{lang.toUpperCase()}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default LanguagesSelect;