import MainLogo from "../assets/images/logo.png";
import UploadFileIcon from "../assets/images/icon_upload.png";
import InfoIcon from "../assets/images/info-icon.png";
import SendIcon from "../assets/images/send_icon.png";
import SendServer from "../assets/images/send_server.png";
import UploadIconNew from "../assets/images/upload_icon.png";
import TelegramIcon from "../assets/images/telegram_icon.png";
import NullImage from "../assets/images/null_icon.png";
import SuccessUpload from "../assets/images/success_upload.png";
import HoverIconIcon from "../assets/images/hover-icon-red.png";
import BlueStarIcon from "../assets/images/icon_star_blue.svg";
import NotificationSuccess from "../assets/images/success.svg";
import NotificationError from "../assets/images/error.svg";
import NotificationWaiting from "../assets/images/spinner.svg";
import reviewIcon1 from "../assets/images/avatars/img1_72x48.jpeg";
import reviewIcon2 from "../assets/images/avatars/img2_72x48.jpeg";
import reviewIcon3 from "../assets/images/avatars/img3_72x48.jpeg";
import reviewIcon4 from "../assets/images/avatars/img4_48x48.jpeg";
import reviewIcon5 from "../assets/images/avatars/img5_72x48.jpeg";
import reviewIcon6 from "../assets/images/avatars/img7_48x32.jpeg";
import reviewIcon7 from "../assets/images/avatars/img8_48x36.jpeg";
import reviewIcon8 from "../assets/images/avatars/img9_48x36.jpeg";
import reviewIcon9 from "../assets/images/avatars/img10_48x32.jpeg";
import reviewIcon10 from "../assets/images/avatars/img11_48x32.jpeg";
import reviewIcon11 from "../assets/images/avatars/img14_72x48.jpeg";
import reviewIcon12 from "../assets/images/avatars/img15_72x48.jpeg";
import reviewIcon13 from "../assets/images/avatars/img18_72x48.jpeg";
import reviewIcon14 from "../assets/images/avatars/img22_72x48.jpeg";
import reviewIcon15 from "../assets/images/avatars/img23_72x48.jpeg";
import reviewIcon16 from "../assets/images/avatars/img24_72x48.jpeg";
import reviewIcon17 from "../assets/images/avatars/img25_32x48.jpeg";
import reviewIcon18 from "../assets/images/avatars/img26_72x48.jpeg";
import reviewIcon19 from "../assets/images/avatars/img27_72x48.jpeg";
import reviewIcon20 from "../assets/images/avatars/img28_72x48.jpeg";
import reviewIcon21 from "../assets/images/avatars/img29_72x48.jpeg";
import reviewPopupIcon from "../assets/images/review_popup-icon.svg";

const reviewIcons = [
    reviewIcon1,
    reviewIcon2,
    reviewIcon3,
    reviewIcon4,
    reviewIcon5,
    reviewIcon6,
    reviewIcon7,
    reviewIcon8,
    reviewIcon9,
    reviewIcon10,
    reviewIcon11,
    reviewIcon12,
    reviewIcon13,
    reviewIcon14,
    reviewIcon15,
    reviewIcon16,
    reviewIcon17,
    reviewIcon18,
    reviewIcon19,
    reviewIcon20,
    reviewIcon21
]

export {
    MainLogo,
    UploadFileIcon,
    InfoIcon,
    SendServer,
    SendIcon,
    UploadIconNew,
    TelegramIcon,
    NullImage,
    SuccessUpload,
    HoverIconIcon,
    BlueStarIcon,
    NotificationSuccess,
    NotificationError,
    NotificationWaiting,
    reviewIcons,
    reviewPopupIcon
}