import {React, Select, useEffect, useTranslation} from '../../../imports/imports-packege';

const SelectList = ({ optionList, getSelectValue, value, error }) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (value) {
            getSelectValue(value);
        }
    }, [value]);

    return (
        <div>
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: error ? '#FF171E' : '#363738',
                        backgroundColor: '#262626',
                        outlineColor: '#363738',
                        borderRadius: 12,
                        padding: '3px 0'
                    }),
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#242426',
                        primary: '#515154',
                    },
                })}
                options={optionList}
                onChange={(selectedOption) => getSelectValue(selectedOption.value)}
                value={optionList.find(option => option.value === value)}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                placeholder={`${t('common.select')}...`}
            />
        </div>
    );
};

export default SelectList;
