import {React, useEffect} from '../../../../imports/imports-packege';
import '../PWAPreviewStyle.css';
import { useData } from "../../../Context/ContextApp";
import TranslateText from '../Translate/text-translate.json';

const BlockCommentQuestion = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];

    return (
        <div className="comment_question">
            <p className="comment_question__content">
                {data.langCode.length > 0 ? ListTextSecurity['footer_title_question'] : 'Was this information useful to you?'}
            </p>
            <div className="btn_container">
                <div className="comment_btn btn__yes">
                    {data.langCode.length > 0 ? ListTextSecurity['footer_answer_yes'] : 'Yes'}
                </div>
                <div className="comment_btn btn__no">
                    {data.langCode.length > 0 ? ListTextSecurity['footer_answer_no'] : 'No'}
                </div>
            </div>
        </div>
    );
};

export default BlockCommentQuestion;