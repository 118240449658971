import {React, StatePool, useEffect, useState} from '../../../../imports/imports-packege';
import  '../PWAPreviewStyle.css';
import PreviewIcon from "../img/rec_1.png";
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";

const AboutSection = () => {
    let {data} = useData();
    const [pwaDesign, setPwaDesign] = useState(data.pwaDesign);

    useEffect(() => {
        setPwaDesign(data.pwaDesign)
    }, [data])

    return (
        <div className="about">
            <div className="about__icon">
                <img src={data.iconUrl === '' ? PreviewIcon : data.iconUrl} className="about__icon-image" id="icon_pwa" alt=""/>
            </div>
            <div className="about__text-info">
                <div className="about__name-pwa" id="name-app">{data.appName === '' ? 'Long name app google' : data.appName}</div>
                <div className="about__info-developer">
                    <div className="about__developer-name" style={{color: pwaDesign ? '#00a173' : '#0d57cf'}}>{data.author === '' ? 'PlayMarket' : data.author}</div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection