import {React, Link, StatePool, useEffect, useTranslation} from "../../imports/imports-packege";
import NavigationStyle from "./NavigationStyle.module.scss";

const ActiveTabNav = StatePool.createState('app');

const Navigation = ({ userTab }) => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = StatePool.useState(ActiveTabNav);

    useEffect(() => {
        let savedTab = localStorage.getItem('activeTab');
        let currentTab = getCurrentTabFromUrl();

        if (currentTab) {
            setActiveTab(currentTab);
            userTab(currentTab);
            localStorage.setItem('activeTab', currentTab);
        } else if (savedTab) {
            setActiveTab(savedTab);
            userTab(savedTab);
        }
    }, [setActiveTab, userTab]);

    const handleActiveTab = (tabName) => {
        setActiveTab(tabName);
        userTab(tabName);
        localStorage.setItem('activeTab', tabName);
    }

    const getCurrentTabFromUrl = () => {
        const path = window.location.pathname;
        if (path.startsWith("/parameters")) return "parameters";
        if (path.startsWith("/reviews")) return "review";
        return "app";
    }

    let queryParams = new URLSearchParams(window.location.search);
    const keitaroId  = queryParams.get('id_land');
    const appId  = queryParams.get('id_onesignal');

    return (
        <div className={NavigationStyle.NavigationSection}>
            <div className={NavigationStyle.NavigationLinkContainer}>
                <ul className={NavigationStyle.NavigationLinkList}>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'app' ? NavigationStyle.active : ''}`}
                            onClick={() => handleActiveTab('app')}
                            to={`/?id_land=${keitaroId}&id_onesignal=${appId}`}>
                            {t('app.title')}
                        </Link>
                    </li>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'parameters' ? NavigationStyle.active : ''}`}
                            to={`/parameters?id_land=${keitaroId}&id_onesignal=${appId}`}
                            onClick={() => handleActiveTab('parameters')}>
                            {t('parameters.title')}
                        </Link>
                    </li>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'review' ? NavigationStyle.active : ''}`}
                            to={`/reviews?id_land=${keitaroId}&id_onesignal=${appId}`}
                            onClick={() => handleActiveTab('review')}>
                            {t('review.title')}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navigation;
