export default {
    common: {
        copy: "Копіювати",
        appStyle: "Стиль додатку",
        error: "невірний {{place}} - помилка",
        select: 'Обрати',
        uploading: 'завантаження...'
    },
    alerts: {
        pwaSuccess: 'ПВА успішно заванатажене в кейтаро!',
        pwaError: 'Помилка при відправці в кейтаро \b Перейдіть заново по посиланню в ТГ',
        addSendLink: 'Додайте посилання на гру',
        sendLinkError: 'Ой, схоже саме цей додаток не вийде поцупити з Play Market, спробуй, будь ласка, інший'
    },
    app: {
        title: "Додаток",
        name: {
            title: "Назва",
            placeholder: "Назва додатку"
        },
        lang: {
            title: "Мова PWA",
        },
        design: {
            title: "Копіювати дизайн додатку з Play Market",
            placeholder: "Посилання з Play Market"
        },
        icon: {
            title: "Іконка",
            placeholder: {
                1: "Від",
                2: "512х512px",
            }
        },
        screenshots: {
            title: "Скріншоти",
            placeholder: {
                1: "Від",
                2: "720х1280px",
            }
        }
    },
    parameters: {
        title: "Параметри",
        author: {
            title: "Розробник",
            placeholder: "Play Dev"
        },
        countComments: {
            title: "Коментарі",
            placeholder: "1034"
        },
        age: {
            title: "Вік"
        },
        size: {
            title: "Розмір (MB)",
            placeholder: "6"
        },
        installations: {
            title: "Кількість інсталяцій",
            placeholder: "2034"
        },
        landDescriptionTitle: {
            title: "Заголовок опису",
            placeholder: "Введіть заголовок"
        },
        landDescriptionText: {
            title: "Опис додатка",
            placeholder: "Введіть опис"
        },
        rating: {
            title: "Рейтинг додатку",
            placeholder: "Введіть число"
        },
        ratingChart: {
            title: "Діаграма рейтингу додатку",
        },
        categories: {
            title: "Список категорій",
        },
        recommended: {
            title: "Рекомендовані додатки",
        }
    },
    review: {
        title: "Відгуки",
        countComments: 'Кількість відгуків',
        comment: {
            title: "Відгук {{index}}",
            icon: 'Іконка коментатора',
            iconPlaceholder: 'Завантажити іконку користувача',
            name: 'Ім\'я коментатора',
            rating: 'Рейтинг коментаря',
            date: 'Дата коментаря',
            text: {
                title: 'Текст коментаря',
                placeholder: 'Введіть текст'
            },
            response: {
                title1: 'Відповідь на коментар',
                title2: '*Якщо поле пусте, воно не з\'явиться на сторінці',
                placeholder: 'Введіть відповідь від розробника'
            }
        },
    }
}